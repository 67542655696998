<script lang="ts" setup>
type Frequency = Responses<'/activities/project/{projectId}'>[number]['reviewInterval']

const model = defineModel<Frequency>()

const ucFirstLabel = computed(() => {
  const val = model.value
  if (val) {
    return val.charAt(0).toUpperCase() + val.slice(1)
  }
  return val
})
</script>

<template>
  <UiBadge
    :label="ucFirstLabel || 'Daily'"
    :tooltip="`It is recommended to review this activity ${model}`"
    icon="i-heroicons-eye"
    color="neutral"
  />
</template>
