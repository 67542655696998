<script lang="ts" setup>
type Operation = Responses<'/activities/project/{projectId}'>[number]['executionType']

const model = defineModel<Operation>()
</script>

<template>
  <UiBadge
    v-if="model === 'ad_hoc'"
    label="Ad-Hoc"
    tooltip="This type of operation refers to activities that could be executed on in as-needed basis"
    icon="i-heroicons-play-circle"
    color="primary"
  />
  <UiBadge
    v-else
    label="Continuous"
    tooltip="This type of activity is recommended to be performed uninterrupted"
    icon="i-heroicons-arrow-path"
    color="primary"
  />
</template>
