<script lang="ts" setup>
type Duration = Responses<'/activities/project/{projectId}'>[number]['durationEstimateDays']

const model = defineModel<Duration>()
</script>

<template>
  <UiBadge
    :label="`${model} days`"
    tooltip="Estimated duration for this activity"
    icon="i-heroicons-calendar-days"
    color="gray"
  />
</template>
